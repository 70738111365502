<template>
  <v-icon :color="complete ? completeColor : notDoneColor">{{ complete ? completeIcon : notDoneIcon }}</v-icon>
</template>

<script>
export default {
  name: 'StatusIcon',
  components: {},
  props: {
    complete: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  created() {},
  data: () => ({
    completeIcon: 'mdi-check',
    completeColor: 'success',
    notDoneIcon: 'mdi-dots-horizontal',
    notDoneColor: 'grey',
  }),
  computed: {},
  methods: {},
};
</script>

<style scoped></style>
